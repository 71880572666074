<template>
  <section class="content-section">
    <h2>April 26th, 12pm - 6pm</h2>
    <h2>What to Expect</h2>
    <ul>
      <li>Good eatin' Crawfish, Potatoes, Corn, Sausage and more!</li>
      <li>A large side yard with plenty of room for you to bring the whole family and your favorite yard games. Bring your own chairs.</li>
      <li>Music, games and good friends</li>
      <li>Spice Krewe flavors and merch available!</li>
    </ul>
    <h2>Back Again This Year!</h2>
    <ul>
      <li>Bryce as the <strong>Spice Krewe MC</strong></li>
      <li><strong>Pardoning of the Crawfish</strong></li>
      <li><strong>Spicy Disc Golf Competition</strong></li>
      <li><strong>2025 Paddle Presentation</strong> and celebration of the boil team</li>
    </ul>
    <figure>
      <img alt="Paddles representing the first 13 years and 2024" src="../assets/paddles-min.png"/>
    </figure>
  </section>
</template>

<script>
export default {
  name: 'WhatToExpect',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.content-section{
  font-size: 1.25rem;
  margin-bottom: 1rem;
}
</style>
