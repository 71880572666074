<template>
  <section class="header">
    <div class="logo">
      <figure>
        <img alt="James and Keisha's Crawfish Boil. 15th Annual" src="../assets/header-2-min.png"/>
      </figure>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CrawfishBoilHeader',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.header{

}
</style>
